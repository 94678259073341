import React, { useContext, useEffect } from "react";

import Header from 'components/Dashboard/Header'

import {
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content,
    HeaderContent
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import DashboardSide from "components/Dashboard/Side";
import useController from "screens/Dashboard/Home/controller";
import { DoLogout } from "services/authentication";
import { CoreContext } from "context/CoreContext";

export default function ContainerAuthenticated({ children, close, saying, noHeader, checkout }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const { setWords, setRanking, setOpened, setUser, setPlan, setDict, setPlans, user, fetchUser, lastKeep, setLastKeep } = useContext(CoreContext)
    useController()

    const init = () => {
        const authentication = ReadObject('authentication')
        if (!authentication?.access_token) {
            return completeNext()
        }
        checkKeep()
    }

    const checkKeep = async () => {
        const delayStamp = (1*(60*(60*(1000)))) // 15min
        if( !lastKeep || (
            (lastKeep + delayStamp) < (new Date().getTime())
        )){
            // console.log("CHECKING KEEP", lastKeep)
            const u = await fetchUser(user?.id)
            if(!u){ return completeNext() }
            // console.log("Checked User", u)
            setLastKeep(new Date().getTime())
        }
    }

    const completeNext = async () => {
        await DoLogout()

        setUser(null)
        setPlan(null)
        setDict(null)
        
        setWords([])
        setRanking([])
        setPlans([])

        setOpened(false)
        setLastKeep(null)

        navigate('login')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <DashboardPage>
                        {noHeader ? null : <Header close={close} saying={saying} checkout={checkout} />}
                        <DashboardBody noHeader={noHeader}>
                            <DashboardBodyContent saying={saying || checkout} >
                                {children}
                            </DashboardBodyContent>
                        </DashboardBody>
                    </DashboardPage>
                </Content>
            </ThemedComponent>
        </>
    );
}