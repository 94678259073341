import React, { useContext, useEffect } from "react";

import {
    HomeContent
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import HomeBody from "components/Dashboard/HomeBody";
import Ranking from "components/Ranking";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import CommonQuestions from "components/CommonQuestions";

export default function FAQ() {
    const { width } = useWindowSize()
    const { setOpened } = useContext(CoreContext)

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                    <CommonQuestions faq />
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}