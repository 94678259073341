export const static_translation = {
    "landpage_banner_balloon": "Enhance a new language!",
    "landpage_flags_title": "Enhance a language with Mobdict:",
    
    "landpage_how_works_title": "Our greatest satisfaction is helping you learn a new language ❤️",
    "landpage_how_works_text": "We are passionate about languages and education. Our goal is to make learning accessible and enjoyable for everyone, using an innovative approach based on dictations.",
    "landpage_how_works_action": "I want to learn more, let's get started",
    
    "landpage_learn_title": "Learn much more 😎",
    "landpage_learn_text": "Our teaching method focuses on practicing languages through dictations, a technique that combines listening comprehension, reading, and writing practice to improve your language skills naturally and effectively.",
    "landpage_learn_action": "Start now",
    
    "landpage_write_title": "Write by Hand and Learn More with Mobdict",
    "landpage_write_text": "Writing by hand can improve vocabulary retention and understanding, as it involves deeper cognitive processes. Submit your handwritten notes to Mobdict using the Mobdict Image Sender app.",
    
    "landpage_free_title": "Try for free 🥹",
    "landpage_free_text": "You can practice the language you're studying, at your current level, by testing Mobdict for free for a week and complete up to 7 dictations to see your vocabulary grow with each challenge.",
    "landpage_free_action": "I want to try",
    
    "landpage_more_title": "Learn in the best way 🎬",
    "landpage_more_text": "Watch our video to understand how learning a language using dictations can benefit you.",
    
    "faq_title": "FAQ - Frequently Asked Questions",
    "faq_question_1": "Who can participate in the course?",
    "faq_answer_1": "To find out who can participate in a course, you need to check the specific requirements set by the institution or the course organizers.",
    "faq_question_2": "Is our course suitable for beginners and those who want to improve their language skills?",
    "faq_answer_2": "Our course is ideal for beginners and for those who want to improve their language skills. Therefore, anyone who is starting out or wants to further develop their language competencies can participate.",
    "faq_question_3": "How does the dictation learning method work?",
    "faq_answer_3": "The dictation learning method is a technique that helps improve listening skills, spelling, and text comprehension.",
    "faq_question_4": "What is the duration of the course?",
    "faq_answer_4": "The duration of the course can vary depending on several factors, such as the institution offering it, the total workload, and the frequency of classes.",
    
    "footer_talk_us": "Contact Us",
    "footer_support": "Support",
    "footer_all_rights": "All rights reserved",
    
    "lg-google-auth": "Enter the Google Authenticator code",

    "dashboard_home_see_plans": "View plans",
    "dashboard_home_dicts_done": "completed",
    "dashboard_home_dicts_plan": "Purchased dictations",
    "dashboard_home_dicts_test": "Trial period",
    "dashboard_home_dicts_test_done": "Trial period completed",
    "dashboard_home_dicts_dict": "Dictations",
    
    "dashboard_home_learning": "I am learning",
    "dashboard_home_level": "Level",
    "dashboard_home_cta": "Let's start the dictation",
    
    "dashboard_plan_title": "Subscribe to one of our plans",
    "dashboard_plan_subtitle": "billed annually",
    "dashboard_plan_pop": "Most popular",
    "dashboard_plan_1": "Basic Plan",
    "dashboard_plan_2": "Intermediate Plan",
    "dashboard_plan_3": "Advanced Plan",
    "dashboard_plan_dict": "dictations",
    "dashboard_plan_action": "Subscribe",
    "dashboard_plan_currency": "R$",
    
    "dashboard_talkus_title": "Contact Us",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_email": "Technical Support",
    "dashboard_talkus_support_address": "Address",
    "dashboard_talkus_support_social": "Social Media",
    "dashboard_talkus_about_title": "About Us",
    "dashboard_talkus_about_text": "At Mobdict, transforming your language learning is easier and more fun with dictations! These little pearls of wisdom offer a unique way to absorb culture and language. As you learn a new language, discover dictations that reflect the way native speakers think and live. Each expression brings valuable lessons and cultural context, helping you master nuances and sound more natural.",
    
    "dashboard_home_ranking_title": "Ranking",
    "dashboard_home_ranking_position": "Pos.",
    "dashboard_home_ranking_name": "Name",
    "dashboard_home_ranking_points": "Points",
    "dashboard_home_ranking_see_more": "See more",

    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Ranking",
    "dashboard_side_buy": "Buy credits",
    "dashboard_side_account": "My account",
    "dashboard_side_settings": "Settings",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Contact us",
    "dashboard_side_exit": "Log out",
    
    "dashboard_form_password_validation": "New password and confirmation do not match",

    "dashboard_form_name": "Full Name",
    "dashboard_form_nickname": "Choose your username",
    "dashboard_form_email": "Email",
    "dashboard_form_phone": "Mobile",
    "dashboard_form_password": "Password",
    "dashboard_form_level": "Level",
    "dashboard_form_confirm_password": "Confirm Password",

    "dashboard_form_account_title": "My Account",
    "dashboard_form_password_title": "Change Password",
    
    "dashboard_form_cancel": "Cancel",
    "dashboard_form_next": "Continue",
    "dashboard_form_save": "Save",
    "dashboard_form_subgender": "Select subgenres",
    
    "dashboard_settings_title": "Settings",
    "dashboard_settings_2fa": "Enable Two-Factor Authentication",
    "dashboard_settings_adjust_pause": "Adjust dictation pause",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_subgender": "Reset favorite subgenres (min. 3):",
    "dashboard_settings_define": "Define",
    "dashboard_settings_default_lang": "Default Language",
    "dashboard_settings_native_lang": "Native Language",
    "dashboard_settings_learn_lang": "Learning Language",
    "dashboard_settings_recurrency": "Recurring subscription",
    
    
    "dashboard_dict_congrats": "Congratulations!",
    "dashboard_dict_added_increase": "You have added",
    "dashboard_dict_added_words": "words to your vocabulary.",
    "dashboard_dict_restart": "Start new dictation",
    "dashboard_dict_back_homepage": "Back to homepage",
    

    "dashboard_dict_wrong_words": "Words are incorrect",
    "dashboard_dict_wrong_omit": "Omitted words",
    "dashboard_dict_wrong_finish": "Finish",
    "dashboard_dict_wrong_challange": "Challenge",
    "dashboard_dict_wrong_reinforcement": "Do reinforcement dictation",
    
    "dashboard_dict_translate": "Translation",
    "dashboard_dict_see_translate": "See translation",
    
    "dashboard_dict_writing_title": "Type the text",
    "dashboard_dict_writing_handed": "Write by hand",
    "dashboard_dict_writing_special": "Special characters",
    
    "dashboard_dict_writing_step_1": "1. Download the Mobdict Image Sender app from the App Store or Play Store and log in.",
    "dashboard_dict_writing_step_2": "2. Write the dictation you heard on a piece of paper and take a photo.",
    "dashboard_dict_writing_step_3": "3. Submit the image through the app and click the button to finish.",
    "dashboard_dict_writing_step_action": "I have sent it",
    "dashboard_dict_writing_step_confirm": "I have checked it, Correct",
    
    "dashboard_dict_writing_progress": "Your progress",
    "dashboard_dict_writing_meta": "Goal",
    "dashboard_dict_writing_words": "words",
    
    "dashboard_register_title": "Enter your details",
    "dashboard_register_subtitle": "Provide your registration details",
    
    "dashboard_password_title": "Create your password",
    "dashboard_register_level": "Select language and level",
    "dashboard_register_subgender": "Select subgenre",
    
    "dashboard_register_terms_1": "By selecting and continuing, you agree to our",
    "dashboard_register_terms_2": "Terms of Service",
    "dashboard_register_and": "and",
    "dashboard_register_terms_3": "Privacy Policy",
    
    "dashboard_register_validation_not_match": "New password and confirmation do not match",
    "dashboard_register_validation_accept_terms": "Accept the terms",
    "dashboard_register_validation_email": "Provide the email",
    "dashboard_register_validation_password": "Provide the new password",
    "dashboard_register_validation_password_confirmation": "Provide the confirmation of the new password",
    "dashboard_register_successfull": "Account created successfully",
    
    "dashboard_register_step1_title": "Enter your details",
    "dashboard_register_step1_text": "Provide your registration details",
    "dashboard_register_step1_action": "Continue",
    
    "dashboard_register_step2_title": "Registration completed successfully",
    "dashboard_register_step2_text": "Thank you for registering.",
    "dashboard_register_step2_action": "Close",
    "dashboard_register_step2_back": "Back",

    "dashboard_forgot_step1_title": "Forgot your password?",
    "dashboard_forgot_step1_text": "Enter your registered email to recover your password",
    "dashboard_forgot_step2_title": "Check your email",
    "dashboard_forgot_step2_text": "Access the recovery link sent to your registered email",
    
    "dashboard_createpassword_step1_title": "New Password",
    "dashboard_createpassword_step1_text": "Enter a new password for your account",
    "dashboard_createpassword_step2_title": "New password created successfully",
    "dashboard_createpassword_step2_text": "You can now log in with your new password",

    "login_mfa": "Using the Google Authenticator app, or another authentication app, scan the QRCode below to generate your token",
    

    "register_validation_subgender": "Select at least 3 subgenres to continue",
    "dict_word_bellow": "Use the words below to complete the text",
    "dict_reinforcement": "Reinforcement dictation",
    "dict_a_newdict": "Start a new dictation",
    "dict_by": "Dictated by",
    "dict_finish": "Finish",
    "dict_next": "Continue",
    "dict_correct": "Correct",
    
    "Autoajuda": "Self-help",
    "Biografia": "Biography",
    "Comédia": "Comedy",
    "Conto": "Short story",
    "Crônica": "Chronicle",
    "Ecologia": "Ecology",
    "Epopeia": "Epic",
    "Fábula": "Fable",
    "Farsa": "Farce",
    "Ficção": "Fiction",
    "Infantojuvenil": "Children and Young Adult",
    "Novela": "Novella",
    "Poesia": "Poetry",
    "Romance": "Novel",
    "Suspense": "Suspense",
    "Tragédia": "Tragedy",
    "True Crime": "True Crime"
}
