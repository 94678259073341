import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Character, HandTitleContainer, SpecialCharacters, SpecialCharacterTitle, WritingButton, WritingButtonContainer, WritingButtonDelete, WritingContainer, WritingContent, WritingHandContent, WritingHandTitle, WritingIcon, WritingImg, WritingImgContent, WritingInputContent } from './styled';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import { Icon } from 'ui/styled';
import ImageModal from 'components/ImageModal';
import { CoreContext } from 'context/CoreContext'; 
import useI18n from 'hooks/useI18n';

export default function Writing({ action, Label, loading, confirmImage }) {
  const { reinforcement, challenge, setToCorrect, form, setForm, imgModal, setImgModal, correct, setCorrect } = useContext(CoreContext)

  const [active, setActive] = useState('totype');
  const [image, setImage] = useState('/images/writing.png');

  const { t } = useI18n(false)

  
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const caracteres = [
    'á', 'à', 'â', 'ã', 'ä', 'ä',
    'é', 'è', 'ê', 'ë', 'í', 'î',
    'ï', 'ó', 'ô', 'õ', 'ö', 'ú',
    'ù', 'ç', 'œ', 'æ', 'ñ', 'ß',
    '¿', '¡'
  ];

  const addLetter = c => {
    changeForm(`${ formValue('text') }${c}`, 'text')
  }

  useEffect(() => {
    if(challenge?.texto){
      setForm({ ...form, text: challenge?.texto })
    }
  }, [challenge])

  return (
    <>
      <WritingContainer>
        {
          reinforcement || challenge ? null : <WritingButtonContainer>
            <WritingButton active={active === 'totype'}
              onClick={() => setActive('totype')}
            >
              { t("dashboard_dict_writing_title") }
            </WritingButton>
            <WritingButton hand active={active === 'hand'}
              onClick={() => setActive('hand')}>
                { t("dashboard_dict_writing_handed") }
            </WritingButton>
          </WritingButtonContainer>
        }
        {active === 'totype' ? <WritingContent>
          <SpecialCharacters>
            <SpecialCharacterTitle>
              { t("dashboard_dict_writing_special") }
            </SpecialCharacterTitle>
            {caracteres.map((caracter, index) => (
              <Character key={index} onClick={() => addLetter(caracter) }>
                {caracter}
              </Character>
            ))}
          </SpecialCharacters>
          <WritingInputContent>
            <Input transparent type='textarea'
              autocomplete="off" spellcheck="false" 
              value={formValue('text')} onChange={e => changeForm(e.target.value, 'text')}
            />
            <Button secondary endIcon='chevron-right' loading={loading} width={challenge ? '240px' : '157px'}
              nospace onClick={() => action(form?.text)}>
              {Label}
            </Button>
          </WritingInputContent>
        </WritingContent> : null}
        {
          active === 'hand' ?
            <>

              <WritingContent row hand>
                {
                  !correct ? <>
                    <Icon icon='writing' />
                    <WritingHandContent>
                      <HandTitleContainer>
                        <WritingHandTitle>
                          { t("dashboard_dict_writing_step_1") }
                        </WritingHandTitle>
                        <WritingHandTitle>
                          { t("dashboard_dict_writing_step_2") }
                        </WritingHandTitle>
                        <WritingHandTitle>
                          { t("dashboard_dict_writing_step_3") }
                        </WritingHandTitle>
                      </HandTitleContainer>
                      <Button secondary endIcon='chevron-right' width={'260px'} loading={loading} nospace onClick={confirmImage}>
                          { t("dashboard_dict_writing_step_action") }
                      </Button>
                    </WritingHandContent>
                  </> : <>
                    <WritingImgContent>
                      <ImageModal image={`data:image/png;base64,${correct?.imagem}`} onRequestClose={() => setImgModal(false)}
                        currentImage={{ src: `data:image/png;base64,${correct?.imagem}`, alt: 'Imagem do ditado' }}
                        isOpen={imgModal}
                      />
                      <WritingImg src={image} onClick={() => setImgModal(true)} />
                      <WritingButtonDelete>
                        <Icon icon='trash' pointer onClick={() => setCorrect(false)} />
                      </WritingButtonDelete>
                    </WritingImgContent>
                    <WritingHandContent>
                      <HandTitleContainer>
                        { correct?.texto_aluno }
                      </HandTitleContainer>
                      <Button secondary endIcon='chevron-right' width={'260px'} nospace loading={loading} onClick={() => action(correct?.texto_aluno)}>
                        { t("dashboard_dict_writing_step_confirm") }
                      </Button>
                    </WritingHandContent>
                  </>
                }

              </WritingContent>
            </>
            : null
        }
      </WritingContainer >
    </>
  )
}
