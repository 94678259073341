import React, { useContext, useState } from 'react';
import { CardBody, CardContainer, CardHeader, CardLabel, FamousCard, FamousTitle, PlansBody } from './styled';
import Button from 'components/Form/Button';
import { Icon } from 'ui/styled';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import { CreateCheckout } from 'services/mobdict';
import { toast } from 'react-toastify';
import { exposeMobdictError } from 'utils';
import useI18n from 'hooks/useI18n';

export default function PlansCard({ plan, dictations, price, action, famous, planIndex, chaves_pesquisa }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const { setModal, user } = useContext(CoreContext)

  const { t } = useI18n(false)

  const [loading, setLoading] = useState(false)

  const handleNavigate = async () => {
    if(!loading){
      setLoading(true)

      const result = await CreateCheckout({
        lookup_key: chaves_pesquisa,
        id: user?.id
      })

      if( !exposeMobdictError(result, !( result?.checkout_url ) )){
          window.open(result?.checkout_url, "new")
          setModal(null);
      }

      setLoading(false)
    }
  }

  return (
    <CardContainer>
      <FamousCard>

        {
          famous && (
            <FamousTitle>
              <Icon icon={'star'} /> { t("dashboard_plan_pop") }
            </FamousTitle>
          )
        }
      </FamousCard>
      <CardHeader plan={(planIndex||0)+1}>
        {plan}
      </CardHeader>
      <PlansBody>
        <CardBody>
          <CardLabel>
            {dictations}
          </CardLabel>
        </CardBody>
        <CardBody>
          <CardLabel>
            <strong>{price}</strong>
          </CardLabel>
        </CardBody>
      </PlansBody>
      <Button secondary loading={loading} nospace onClick={handleNavigate}>
        {action}
      </Button>
    </CardContainer>
  );
}
