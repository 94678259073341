import React, { useContext, useEffect } from "react";
import { CoreContext } from "context/CoreContext";
import { toast } from "react-toastify";
import { exposeMobdictError } from "utils";
import { ReadTranslation } from "services/mobdict";


export default function useI18n(fetchable = true){
    
    const { language, setLanguage, translation, setTranslation } = useContext(CoreContext)
    
    const t = ref => translation?.translations?.[ref] || ref ;

    const loadTranslation = async () => {
        if(language){
            const result = await ReadTranslation({ idioma: language })
            if( !exposeMobdictError(result, (!!result?.data?.traducoes) ) ){
                const { static_translation } = (require(`utils/i18n/${language.toLowerCase()}`)||require(`utils/i18n/portuguese`)||({ static_translation:[] }))
                const next_translation = {
                    language: language,
                    translations:{ ...static_translation, ...(result?.data?.traducoes || {}) }
                }
                // console.log("fetch:result", next_translation)
                setTranslation(next_translation)
            }else{
                const { static_translation } = (require(`utils/i18n/${language.toLowerCase()}`)||require(`utils/i18n/portuguese`)||({ static_translation:[] }))
                const next_translation = {
                    language: language,
                    translations:{ ...static_translation }
                }
                // console.log("fallback:result", next_translation)
                setTranslation(next_translation)
            }
        }
    }

    useEffect(() => {
        if(fetchable){
            const ignoreCache = false
            if((language && language !== translation?.language || ignoreCache)){
                // console.log("effect:loadTranslation:", language )
                loadTranslation()
            } else { 
                // console.log("effect:empty:or:cached", language, translation ) ;
            }
        }
    }, [language])

    return {
        t,
        language,
        translation,
        setLanguage
    }
}