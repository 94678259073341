import React, { useContext, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PinInput from 'react-pin-input';

import {
    FormTitle,
    FormText,
    FormSpacer,
    RegisterForgot,
    ForgotLink,
    RegisterCall,
    FormContent,
    ContentIcon,
    LoginContent,
    ImageBackground,
    CloseIcon,
    RecaptchaContainer,
    CheckboxLabel,
    RecaptchaIcon,
    Span,
    RecaptchaIconContent,
    FormBorder,
    StyledPinInput,
    RecaptchaEmbededContainer,
    QrContainer
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { DoLogin } from "services/authentication";
import { exposeMobdictError, exposeStrapiError } from "utils";
import { Icon, Text, Title } from "ui/styled";
import Check from "components/Form/Check";
import { theme } from "ui/theme-color";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginUser, LoginUserGoogle, LoginUserKey } from "services/mobdict";

import { useGoogleLogin } from '@react-oauth/google';
import useI18n from "hooks/useI18n";
import { jwtDecode } from 'jwt-decode';
import { fetchGoogleUser } from "services/api";

import QRCode from "react-qr-code";

export default function Login() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    
    const [secureCode, setSecureCode] = useState(null)

    const [loading, setLoading] = useState(false)
    const [loadingSocial, setLoadingSocial] = useState(false)
    const { setUser, setLastKeep } = useContext(CoreContext)
    const [checked, setChecked] = useState(false)
        
    const [refreshingCaptcha, setRefreshingCaptcha ] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const [validated, setValidated] = useState(false)

    const {t} = useI18n()


    const captchaForm = useRef()
    const recaptchaKey = `6LcKtQMqAAAAAMDXQ2IkXwktA7FkWSmT-znC3bPl`

    const valid = (verbose = false) => {

        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: ${ t('lg-email') }`); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: ${ t('lg-senha') }`); }
            return false;
        }

        if (!validated) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: reCAPTCHA`); }
            return false;
        }

        return true
    }

    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => completeLoginGoole(tokenResponse),
    });

    const login = async () => {
        if (!valid(true) || loading) { return; }
        setLoading(true)

        const payload = { 
            email: form?.identifier,
            senha: form?.password,
            tipo: "alnp",
            recaptcha: validated,
            url: "https://mobdict.com/apl/validate-email"
        }

        // console.log("payload", payload)

        const result = await LoginUser(payload)

        // console.log("result", result)
        setLoading(false)

        if( !exposeMobdictError(result, (!!result?.data?.access_token) )){
            setLastKeep(new Date().getTime())
            completeLogin(result)
        } else {
            setRefreshingCaptcha(true)
            setTimeout(() => { setRefreshingCaptcha(false) ;}, 1)
        }
    }

    const completeLogin = (result) => {
        // if (result?.user) { setUser(result.user) }
        if(result?.data?.mfa === "True" ){

            setSecureCode( result?.data )

            return;
        } 

        navigate('dashboard')
    }

    const loginMfa = async () => {
        if ( loading ) { return; }
        setLoading(true)
        
        const result = await LoginUserKey({
            "chave": secureCode?.chave,
            "token": form?.code,
            "email": form?.identifier
        })
        
        if( !exposeMobdictError(result, !(typeof result?.data === 'string') )){
            navigate('dashboard')
        }

        setLoading(false)
    }

    const handleCheck = () => {
        setChecked(!checked)
    }

    const completeLoginGoole = async (token) => {
        // if (loadingSocial) { return; }
        
        setLoadingSocial(true) 

        console.log("Google token", token)
        const decoded = await fetchGoogleUser(token?.access_token); 
        console.log("Google decoded", decoded)

        const payload = {
            email: decoded.email,
            tipo: 'alnp',
            senha: decoded.sub,
        }

        // console.log("payload", payload)

        const result = await LoginUserGoogle(payload)

        // console.log("result", result)
        setLoadingSocial(false)

        if( !exposeMobdictError(result, (!!result?.data?.access_token) )){
            setLastKeep(new Date().getTime())


            completeLogin(result)
        }

    }

    return (
        <>
            <ContainerUnauthenticated login>
                <LoginContent>
                    <CloseIcon onClick={() => navigate('')} />
                    <ImageBackground src={'/images/background.png'} />
                    <Icon icon='logo-white' width={180} />
                    {secureCode ? null : <>
                        <FormContent>
                            <Title hasIcon>
                                <Icon icon='logo-mobdict' width={44} nomargin />
                                { t("title") }
                            </Title>
                            <FormSpacer />
                            <Input placeholder={ t('lg-email') } value={formValue('identifier')} onChange={e => changeForm(e.target.value, 'identifier')} />
                            <FormSpacer />
                            <Input placeholder={ t('lg-senha') } type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} onSubmitEditing={login} />
                            <RegisterForgot>
                                <Button nospace link primary onClick={() => navigate('forgot')}>{ t('lg-fgp') }</Button>
                            </RegisterForgot>

                            {/* <RecaptchaContainer>
                                <Check size={'large'} label="I'm not a robot" checked={checked} onChange={handleCheck} />
                                <RecaptchaIconContent>
                                    <RecaptchaIcon />
                                    <Span>Privacy - Terms</Span>
                                </RecaptchaIconContent>
                            </RecaptchaContainer> */}

                            {
                                refreshingCaptcha ? null : 
                                <RecaptchaEmbededContainer>
                                    <ReCAPTCHA
                                        ref={captchaForm}
                                        onChange={setValidated}
                                        sitekey={recaptchaKey}
                                    />
                                </RecaptchaEmbededContainer>
                            }

                            <Button large secondary nospace loading={loading} onClick={login}>{ t('login') }</Button>
                            <FormSpacer />
                            <Button large startIcon={'google'} loadingSocial={loadingSocial} nospace color={'tertiary'} onClick={loginGoogle}>{ t('login') } google</Button>
                            <FormBorder />
                            <Button large outline secondary nospace colorBlack onClick={() => navigate('register')}>{ t('lg-acc') }</Button>
                        </FormContent>
                    </>}
                    {
                        !secureCode ? null : <>
                            <FormContent>

                                {
                                    !secureCode?.qr_url ? null : <>
                                        <Text center>{ t("login_mfa") }</Text>
                                        <QrContainer>
                                            <QRCode size={200} value={secureCode?.qr_url} />
                                        </QrContainer>
                                    </>
                                }

                                <Title center={!!secureCode?.qr_url} small>{ t("lg-google-auth") }</Title>
                                <FormSpacer />
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    // secret
                                    // secretDelay={10000} 
                                    onChange={(value, index) => changeForm(value, 'code')}
                                    type="numeric"
                                    inputMode="number"
                                    style={{
                                        gap: '8px', display: "flex", justifyContent: "space-between", flexWrap: 'wrap',
                                    }}
                                    inputStyle={{
                                        background: theme.palette.colors.backgroundgrey, color: 'black', width: 46, height: 46, borderRadius: 6, fontSize: 20, fontWeight: "400", fontFamily: "Poppins",
                                        borderColor: theme.palette.colors.backgroundgrey,
                                    }}
                                    inputFocusStyle={{ borderColor: theme.palette.colors.backgroundgrey }}
                                    onComplete={(value, index) => changeForm(value, 'code')}
                                    autoSelect={true}
                                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                />
                                <FormSpacer />
                                <Button small secondary loading={loading} onClick={loginMfa}>
                                    { t('login') }
                                </Button>
                            </FormContent>
                        </>
                    }
                </LoginContent>
            </ContainerUnauthenticated>
        </>
    );
}