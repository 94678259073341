import React from 'react';
import { Container, PercentageText, ProgressBarBackground, ProgressBarForeground, ProgressContainer, ProgressText, Title } from './styled';
import useI18n from 'hooks/useI18n';


const ProgressBar = ({ progress,meta }) => {
  const { t } = useI18n(false)
  return (
    <Container>
      <ProgressText>

      { t("dashboard_dict_writing_progress") }
        
      </ProgressText>
      <ProgressContainer>
        <ProgressBarBackground >
          <ProgressBarForeground progress={progress} >
            <PercentageText >{`${progress}`}</PercentageText>
          </ProgressBarForeground>
        </ProgressBarBackground>
      </ProgressContainer>
      {
        !meta ? null :
        <ProgressText>
          { t("dashboard_dict_writing_meta") }: { meta } { t("dashboard_dict_writing_words") }
        </ProgressText>
      }
    </Container>
  );
};

export default ProgressBar;
