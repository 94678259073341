import React, { useState } from 'react'
import { ResponseContent, ResponseText, ResponseTitle } from './styled'

export default function Response({ response, challenge }) {

  return (
    <>
      <ResponseContent >
        {response ?
          <>
            <ResponseTitle>
              Resposta do desafio
            </ResponseTitle>
            <ResponseText>
              { challenge?.resposta }
            </ResponseText>
          </>
          : null
        }
      </ResponseContent>
    </>
  )
}
