import Select from 'components/Form/Select'
import InfoHeader from 'components/InfoHeader'
import React, { useContext } from 'react'
import { DashboardMenuHeader, IconContent } from './styled'
import { Icon } from 'ui/styled'
import { languages, optionsLanguage } from 'utils/options'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useI18n from 'hooks/useI18n'

export default function SectionHeader({ close }) {

  const { opened, setOpened, handleStateReset } = useContext(CoreContext)

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const {
    t,
    language,
    setLanguage
  } = useI18n()

  const handleClose = () => {
    handleStateReset()
    navigate('dashboard')
  }

  return (
    <>
      <DashboardMenuHeader>
        <InfoHeader />

        {close ?
          <Icon pointer icon='close-black' onClick={handleClose} /> :
          <Select
            small
            fitContent
            border
            startIcons={
              <IconContent>
                <Icon icon='mundi' />
                <Icon icon={ `${language}`.toLowerCase() } />
              </IconContent>
            }
            width={'187px'}
            placeholder='Português'
            
            options={optionsLanguage}
            value={language}
            onChange={setLanguage}
          />}
      </DashboardMenuHeader>
    </>
  )
}
