import React, { useContext, useState } from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuHeaderUserContent,
    DashboardMenuHeaderUserImage,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,

    DashboardVersionContent,
    DashboardVersionText

} from "./styled";

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { Icon, SafeImage } from "ui/styled";
import { CoreContext } from "context/CoreContext";
import useI18n from "hooks/useI18n";

export default function DashboardSide({ opened, setOpened }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const pathname = history.location.pathname;

    const { setModal, setWords, setRanking, setUser, setPlan, setDict, setPlans } = useContext(CoreContext)

    const { t } = useI18n(false)

    const [active, setActive] = useState(0);

    const page = pathname.split('/')[1]

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            setOpened(false)
        }
    }

    const exit = async () => {
        await DoLogout()

        setUser(null)
        setPlan(null)
        setDict(null)
        
        setWords([])
        setRanking([])
        setPlans([])
        
        navigate('login')
    }

    const menuItems = [
        {
            name: t("dashboard_side_home"),
            onclick: () => navigate('dashboard'),
            page: 'dashboard'
        },
        {
            name:  t("dashboard_side_ranking"),
            onclick: () => navigate('ranking'),
            page: 'ranking'
        },
        {
            name:  t("dashboard_side_buy"),
            onclick: () => setModal({ type: 'plans' }),
        },
        {
            name:  t("dashboard_side_account"),
            onclick: () => navigate('profile'),
            page: 'profile'
        },
        {
            name:  t("dashboard_side_settings"),
            onclick: () => navigate('settings'),
            page: 'settings'

        },
        {
            name:  t("dashboard_side_faq"),
            onclick: () => navigate('faq'),
            page: 'faq'
        },
        {
            name:  t("dashboard_side_talk_us"),
            onclick: () => navigate('contact'),
            page: 'contact'
        }

    ];

    const handleClick = (page) => {
        if (page) {
            navigate(page)
        }
        return;
    }

    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer >
                        <DashboardMenu>
                            <DashboardMenuHeader >
                                <SafeImage src={'/icons/logo-blue.svg'} />
                                <DashboardMenuHeaderIcon src={'/icons/close-black.svg'} onClick={() => setOpened(false)} />
                            </DashboardMenuHeader>
                            <DashboardMenuContent>
                                {
                                    menuItems.map((item, index) => (
                                        <DashboardMenuOption key={index}
                                            onClick={() => !item.onclick ? null : item.onclick()}
                                            active={page === item.page}
                                        >
                                            {item.name}
                                        </DashboardMenuOption>
                                    ))
                                }
                            </DashboardMenuContent>

                            <DashboardMenuFooter>
                                <DashboardMenuOption primary outline onClick={exit} centred>
                                    { t("dashboard_side_exit") }
                                </DashboardMenuOption>
                            </DashboardMenuFooter>

                        </DashboardMenu>
                    </DashboardMenuContainer>
            }
        </>
    );
}