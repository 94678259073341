import React, { useContext, useEffect } from 'react'
import { SuccessContainer, SuccessContent } from './styled'
import { Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import useI18n from 'hooks/useI18n';

export default function Success({ restart, loading }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const { setNoHeader, handleStateReset, toCorrect, challenge } = useContext(CoreContext)

  const { t } = useI18n(false)

  useEffect(() => {
    setNoHeader(true)
  }, [])
 

  const back = () => {
    handleStateReset()
    navigate('dashboard')
  }

  return (
    <>
      <SuccessContainer>
        <Icon icon='success' width={100} />
        <SuccessContent>
          <Title center>
            { t("dashboard_dict_congrats") }
          </Title>
          {
            challenge ? null :
            <Title center>
            { t("dashboard_dict_added_increase") } "{ toCorrect?.qtd_novas_conhecidas }" { t("dashboard_dict_added_words") }
            </Title>
          }
        </SuccessContent>
        <SuccessContent>
          <Button width='308px' secondary nospace onClick={restart} loading={loading}>
          { t("dashboard_dict_restart") }
          </Button>
          <Button width='308px' secondary outline colorBlack onClick={back}>
          { t("dashboard_dict_back_homepage") }
          </Button>
        </SuccessContent>
      </SuccessContainer>
    </>
  )
}
