export const optionsStates = [
    { id: 1, title: 'AC', name: 'Acre' },
    { id: 2, title: 'AL', name: 'Alagoas' },
    { id: 3, title: 'AP', name: 'Amapa' },
    { id: 4, title: 'AM', name: 'Amazonas' },

    { id: 5, title: 'BA', name: 'Bahia' },
    { id: 6, title: 'CE', name: 'Ceara' },
    { id: 7, title: 'DF', name: 'Distrito Federal' },
    { id: 8, title: 'ES', name: 'Espirito Santo' },
    { id: 9, title: 'GO', name: 'Goias' },
    { id: 10, title: 'MA', name: 'Maranhão' },

    { id: 11, title: 'MT', name: 'Mato Grosso' },
    { id: 12, title: 'MS', name: 'Mato Grosso do Sul' },
    { id: 13, title: 'MG', name: 'Minas Gerias' },
    { id: 14, title: 'PA', name: 'Pará' },
    { id: 15, title: 'PB', name: 'Paraíba' },

    { id: 16, title: 'PR', name: 'Paraná' },
    { id: 17, title: 'PE', name: 'Pernambuco' },
    { id: 18, title: 'PI', name: 'Piauí' },
    { id: 19, title: 'RJ', name: 'Rio de Janeiro' },

    { id: 20, title: 'RN', name: 'Rio Grande do Norte' },
    { id: 21, title: 'RS', name: 'Rio Grande do Sul' },
    { id: 22, title: 'RO', name: 'Rondônia' },
    { id: 23, title: 'RR', name: 'Roraima' },

    { id: 24, title: 'SC', name: 'Santa Catarina' },
    { id: 25, title: 'SP', name: 'São Paulo' },
    { id: 26, title: 'SE', name: 'Sergipe' },
    { id: 27, title: 'TO', name: 'Tocântins' },
]

export const languages = [
    { id: 1, title: 'Português' },
    { id: 2, title: 'Inglês' },
    { id: 3, title: 'Espanhol' },
    { id: 4, title: 'Francês' },
    { id: 5, title: 'Italiano' },
    { id: 6, title: 'Alemão' },
    { id: 7, title: 'Chinês' },
    { id: 8, title: 'Japonês' },
    { id: 9, title: 'Coreano' },
    { id: 10, title: 'Russo' },
    { id: 11, title: 'Árabe' },
    { id: 12, title: 'Hebraico' },
    { id: 13, title: 'Grego' },
    { id: 14, title: 'Latim' },
    { id: 15, title: 'Outro' },
]

export const nivels = [
    { id: 1, title: 'Iniciante' },
    { id: 2, title: 'Intermediário' },
    { id: 3, title: 'Avançado' },
    { id: 4, title: 'Fluente' },
]

//

export const optionsLanguage = [
    { id:'Portuguese', title:'Portuguese'},
    { id:'English', title:'English'},
    { id:'German', title:'German'},
    { id:'Italian', title:'Italian'},
    { id:'French', title:'French'},
    { id:'Spanish', title:'Spanish'},
    { id:'Hindi', title:'Hindi'},
    { id:'Mandarin', title:'Chinese'},
]

export const optionsLevel = [
    { id:1, title:'A1'},
    { id:2, title:'A2'},
    { id:3, title:'B1'},
    { id:4, title:'B2'},
    { id:5, title:'C1'},
    { id:6, title:'C2'},
]



export const optionize = item => {
    return Object.keys(item||{})?.map(k => ({ id: item?.[k], title:k }))
}

export const optionizeArray = array => {
    return array?.map(m => ({ id: m, title:m }))
}