import React, { useContext, useState } from 'react'
import { ButtonContent, FormBorder, HomeBodyContainer, HomeBodyContent, InfoStudyingIcon } from './styled'
import { ButtonContainer, Icon } from 'ui/styled'
import Button from 'components/Form/Button'
import InfoStudying from 'components/InfoStudying'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'
import { ReadObject } from 'services/storage'
import { CreateDictByUserId } from 'services/mobdict'
import { exposeMobdictError } from 'utils'
import { toast } from 'react-toastify'

export default function HomeBody() {

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const { opened, setOpened, user, setDict } = useContext(CoreContext)

  const { t } = useI18n(false)

  const [loading, setLoading] = useState(false)


  const handleNavigation = async () => {

    await beginDict()

  }

  const beginDict = async () => {
    if(!loading && user?.id){
      setLoading(true)
        const auth = await ReadObject('authentication')
        const result = await CreateDictByUserId({
          tipo: auth?.tipo,
          break_time: user?.pausa || 9
        }, user?.id)

      console.log("Dict", result)

      if( !exposeMobdictError(result, !!(result?.data?.voz) ) ){
        setDict(result?.data)
        setOpened(false)
        navigate('saying')
      }
        
      setLoading(false)
    }
  } 



  return (
    <>
      <HomeBodyContainer>
        <InfoStudying />
        <InfoStudyingIcon src='/icons/studying.svg' />
        <FormBorder />
        <ButtonContainer center>
          <ButtonContent>
            <Button endIcon='chevron-right' loading={loading} nospace secondary onClick={handleNavigation}>
              { t("dashboard_home_cta") }
            </Button>
          </ButtonContent>
        </ButtonContainer>
      </HomeBodyContainer>
    </>
  )
}
