import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { theme } from 'ui/theme-color';
import { PhoneContainer, PhoneInputLabel } from './styled';
import { InputLabel } from '@mui/material';
import useI18n from 'hooks/useI18n';

const PhoneNumberInput = ({ id, secondary, value, onChange}) => {
  
  const availableCountries = ['br']

  const { t } = useI18n(false)

  return (
    <PhoneContainer>
      <InputLabel htmlFor={id}
        color={secondary ? 'secondary' : 'primary'} >{ t("dashboard_form_phone") }</InputLabel>
      <PhoneInput
        country={'br'}
        onlyCountries={availableCountries}
        value={value}
        onChange={onChange}
        enableSearch
        autoFormat
        inputStyle={{
          width: '100%',
          height: '48px',
          fontSize: '16px',
          borderRadius: '8px',
          border: 'none',
          background: theme.palette.colors.backgroundgrey,
          boxShadow: 'none',
        }}
        buttonStyle={

          {
            border: 'none',
            background: theme.palette.colors.backgroundgrey,
            borderRadius: '8px',
          }
        }
      />
    </PhoneContainer>
  );
};

export default PhoneNumberInput;

