export const static_translation = {
    "landpage_banner_balloon": "Migliora una nuova lingua!",
    "landpage_flags_title": "Migliora una lingua con Mobdict:",
    
    "landpage_how_works_title": "La nostra maggiore soddisfazione è aiutare nell'apprendimento di una nuova lingua ❤️",
    "landpage_how_works_text": "Siamo appassionati di lingue e istruzione. Il nostro obiettivo è rendere l'apprendimento accessibile e divertente per tutti, utilizzando un approccio innovativo basato sui dettati.",
    "landpage_how_works_action": "Voglio saperne di più, iniziamo",
    
    "landpage_learn_title": "Impara molto di più 😎",
    "landpage_learn_text": "Il nostro metodo di insegnamento si concentra sulla pratica delle lingue attraverso i dettati, una tecnica che combina comprensione orale, lettura e pratica scritta per migliorare le tue abilità linguistiche in modo naturale ed efficace.",
    "landpage_learn_action": "Inizia ora",
    
    "landpage_write_title": "Scrivi a mano e impara di più con Mobdict",
    "landpage_write_text": "Scrivere a mano può migliorare la memorizzazione e la comprensione del vocabolario, poiché coinvolge processi cognitivi più profondi. Invia i tuoi manoscritti a Mobdict utilizzando l'app Mobdict Image Sender.",
    
    "landpage_free_title": "Prova gratuita 🥹",
    "landpage_free_text": "Puoi esercitarti con la lingua che stai studiando, al livello attuale, provando Mobdict gratuitamente per una settimana e fare fino a 7 dettati per vedere il tuo vocabolario crescere con ogni sfida.",
    "landpage_free_action": "Voglio provare",
    
    "landpage_more_title": "Impara nel miglior modo 🎬",
    "landpage_more_text": "Guarda il nostro video per capire come l'apprendimento delle lingue tramite dettati può beneficiarti.",
    
    "faq_title": "FAQ - Domande frequenti",
    "faq_question_1": "Chi può partecipare al corso?",
    "faq_answer_1": "Per sapere chi può partecipare a un corso, è necessario verificare i requisiti specifici stabiliti dall'istituzione o dai responsabili del corso.",
    "faq_question_2": "Il nostro corso è ideale per principianti e per coloro che desiderano migliorare le loro competenze linguistiche.",
    "faq_answer_2": "Il nostro corso è ideale per principianti e per coloro che desiderano migliorare le loro competenze linguistiche. Pertanto, chiunque stia iniziando o voglia sviluppare ulteriormente le proprie competenze nella lingua può partecipare.",
    "faq_question_3": "Come funziona il metodo di apprendimento tramite dettati?",
    "faq_answer_3": "Il metodo di apprendimento tramite dettati è una tecnica che aiuta a migliorare l'ascolto, l'ortografia e la comprensione del testo.",
    "faq_question_4": "Qual è la durata del corso?",
    "faq_answer_4": "La durata del corso può variare a seconda di diversi fattori, come l'istituzione che lo offre, il carico orario totale e la frequenza delle lezioni.",
    
    "footer_talk_us": "Contattaci",
    "footer_support": "Supporto",
    "footer_all_rights": "Tutti i diritti riservati",
    
    "lg-google-auth": "Inserisci il codice di autenticazione di Google Authenticator",

    "dashboard_home_see_plans": "Visualizza piani",
    "dashboard_home_dicts_done": "completati",
    "dashboard_home_dicts_plan": "Dettati acquistati",
    "dashboard_home_dicts_test": "Periodo di prova",
    "dashboard_home_dicts_test_done": "Periodo di prova completato",
    "dashboard_home_dicts_dict": "Dettati",
    
    "dashboard_home_learning": "Sto imparando",
    "dashboard_home_level": "Livello",
    "dashboard_home_cta": "Iniziamo il dettato",
    
    "dashboard_plan_title": "Abbonati a uno dei nostri piani",
    "dashboard_plan_subtitle": "addebito annuale",
    "dashboard_plan_pop": "Piano più popolare",
    "dashboard_plan_1": "Piano Base",
    "dashboard_plan_2": "Piano Intermedio",
    "dashboard_plan_3": "Piano Avanzato",
    "dashboard_plan_dict": "dettati",
    "dashboard_plan_action": "Abbonati",
    "dashboard_plan_currency": "R$",
    
    "dashboard_talkus_title": "Contattaci",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_email": "Supporto tecnico",
    "dashboard_talkus_support_address": "Indirizzo",
    "dashboard_talkus_support_social": "Social media",
    "dashboard_talkus_about_title": "Chi siamo",
    "dashboard_talkus_about_text": "Con Mobdict, migliorare il tuo apprendimento delle lingue è più facile e divertente con i dettati! Questi piccoli frammenti di saggezza offrono un modo unico per assorbire cultura e lingua. Mentre impari una nuova lingua, scopri dettati che riflettono il modo di pensare e vivere dei parlanti nativi. Ogni espressione porta una lezione preziosa e un contesto culturale, aiutandoti a padroneggiare le sfumature e a suonare più naturale.",
    
    "dashboard_home_ranking_title": "Classifica",
    "dashboard_home_ranking_position": "Pos.",
    "dashboard_home_ranking_name": "Nome",
    "dashboard_home_ranking_points": "Punti",
    "dashboard_home_ranking_see_more": "Vedi di più",

    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Classifica",
    "dashboard_side_buy": "Compra crediti",
    "dashboard_side_account": "Il mio account",
    "dashboard_side_settings": "Impostazioni",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Contattaci",
    "dashboard_side_exit": "Esci",
    
    "dashboard_form_password_validation": "Nuova password e conferma non corrispondono",

    "dashboard_form_name": "Nome completo",
    "dashboard_form_nickname": "Scegli il tuo nome utente",
    "dashboard_form_email": "Email",
    "dashboard_form_phone": "Cellulare",
    "dashboard_form_password": "Password",
    "dashboard_form_level": "Livello",
    "dashboard_form_confirm_password": "Conferma password",

    "dashboard_form_account_title": "Il mio account",
    "dashboard_form_password_title": "Cambia password",
    
    "dashboard_form_cancel": "Annulla",
    "dashboard_form_next": "Continua",
    "dashboard_form_save": "Salva",
    "dashboard_form_subgender": "Seleziona i sottogeneri",
    
    "dashboard_settings_title": "Impostazioni",
    "dashboard_settings_2fa": "Abilita l'autenticazione a due fattori",
    "dashboard_settings_adjust_pause": "Regola la pausa nel dettato",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_subgender": "Ripristina sottogeneri preferiti (min. 3):",
    "dashboard_settings_define": "Definisci",
    "dashboard_settings_default_lang": "Lingua predefinita",
    "dashboard_settings_native_lang": "Lingua madre",
    "dashboard_settings_learn_lang": "Lingua da apprendere",
    "dashboard_settings_recurrency": "Abbonamento ricorrente",
    
    
    "dashboard_dict_congrats": "Congratulazioni!",
    "dashboard_dict_added_increase": "Hai aggiunto altre",
    "dashboard_dict_added_words": "parole al tuo vocabolario.",
    "dashboard_dict_restart": "Inizia un nuovo dettato",
    "dashboard_dict_back_homepage": "Torna alla home",
    

    "dashboard_dict_wrong_words": "Parole errate",
    "dashboard_dict_wrong_omit": "Parole omesse",
    "dashboard_dict_wrong_finish": "Termina",
    "dashboard_dict_wrong_challange": "Sfida",
    "dashboard_dict_wrong_reinforcement": "Fai un dettato di rinforzo",
    
    "dashboard_dict_translate": "Traduzione",
    "dashboard_dict_see_translate": "Vedi traduzione",
    
    "dashboard_dict_writing_title": "Digita il testo",
    "dashboard_dict_writing_handed": "Scrivere a mano",
    "dashboard_dict_writing_special": "Caratteri speciali",
    
    "dashboard_dict_writing_step_1": "1. Scarica l'app Mobdict Image Sender dall'App Store o dal Play Store e accedi.",
    "dashboard_dict_writing_step_2": "2. Scrivi il dettato che hai ascoltato su un foglio e scatta una foto.",
    "dashboard_dict_writing_step_3": "3. Invia l'immagine tramite l'app e fai clic sul pulsante accanto per completare.",
    "dashboard_dict_writing_step_action": "Ho già inviato",
    "dashboard_dict_writing_step_confirm": "Ho già verificato, Correggi",
    
    "dashboard_dict_writing_progress": "Il tuo progresso",
    "dashboard_dict_writing_meta": "Obiettivo",
    "dashboard_dict_writing_words": "parole",
    
    "dashboard_register_title": "Inserisci i tuoi dati",
    "dashboard_register_subtitle": "Fornisci i tuoi dati di registrazione",
    
    "dashboard_password_title": "Crea la tua password",
    "dashboard_register_level": "Seleziona la lingua e il livello",
    "dashboard_register_subgender": "Seleziona il sottogenere",
    
    "dashboard_register_terms_1": "Selezionando e continuando, accetti i nostri",
    "dashboard_register_terms_2": "Termini di servizio",
    "dashboard_register_and": "e",
    "dashboard_register_terms_3": "Politica sulla privacy",
    
    "dashboard_register_validation_not_match": "La nuova password e la conferma non corrispondono",
    "dashboard_register_validation_accept_terms": "Accetta i termini",
    "dashboard_register_validation_email": "Inserisci l'email",
    "dashboard_register_validation_password": "Inserisci la nuova password",
    "dashboard_register_validation_password_confirmation": "Inserisci la conferma della nuova password",
    "dashboard_register_successfull": "Account creato con successo",
    
    "dashboard_register_step1_title": "Inserisci i tuoi dati",
    "dashboard_register_step1_text": "Fornisci i tuoi dati di registrazione",
    "dashboard_register_step1_action": "Continua",
    
    "dashboard_register_step2_title": "Registrazione completata con successo",
    "dashboard_register_step2_text": "Grazie per esserti registrato.",
    "dashboard_register_step2_action": "Chiudi",
    "dashboard_register_step2_back": "Indietro",

    "dashboard_forgot_step1_title": "Hai dimenticato la tua password?",
    "dashboard_forgot_step1_text": "Inserisci la tua email registrata per recuperare la password",
    "dashboard_forgot_step2_title": "Controlla la tua email",
    "dashboard_forgot_step2_text": "Accedi al link di recupero inviato alla tua email registrata",
    
    "dashboard_createpassword_step1_title": "Nuova password",
    "dashboard_createpassword_step1_text": "Inserisci una nuova password per il tuo account",
    "dashboard_createpassword_step2_title": "Nuova password creata con successo",
    "dashboard_createpassword_step2_text": "Ora puoi accedere con la tua nuova password",

    "login_mfa": "Utilizzando l'app Google Authenticator o un'altra app di autenticazione, scansiona il codice QR riportato di seguito per generare il tuo token",
    
    "register_validation_subgender": "Seleziona almeno 3 sottogeneri per continuare",
    "dict_word_bellow": "Usa le parole qui sotto per completare il testo",
    "dict_reinforcement": "Dittato di rinforzo",
    "dict_a_newdict": "Iniziare un nuovo dittato",
    "dict_by": "Dittato da",
    "dict_finish": "Terminare",
    "dict_next": "Continua",
    "dict_correct": "Correggi",
    
    "Autoajuda": "Autoaiuto",
    "Biografia": "Biografia",
    "Comédia": "Commedia",
    "Conto": "Racconto",
    "Crônica": "Cronaca",
    "Ecologia": "Ecologia",
    "Epopeia": "Epopea",
    "Fábula": "Favola",
    "Farsa": "Farsa",
    "Ficção": "Fiction",
    "Infantojuvenil": "Letteratura per ragazzi",
    "Novela": "Novella",
    "Poesia": "Poesia",
    "Romance": "Romanzo",
    "Suspense": "Suspense",
    "Tragédia": "Tragedia",
    "True Crime": "Crimine reale"
}
