import styled from 'styled-components'

export const BannerImage = styled.div.attrs({
})`           

    position: relative;
    flex: 1;
    max-height: 717.73px;
    min-height: 717.73px;
    z-index: 1;
    background: white url(/images/banner.jpeg) no-repeat center / cover;
    overflow: hidden;
    position: relative;
    @media (max-width: 991px) {
        max-height: 400px;
        min-height: 400px;
        width: 100%;
    }
`;


export const MobidictOutlined = styled.div.attrs({
})`           
    z-index: 2;
    position: absolute;
    right: 0;
    @media (max-width: 991px) {

    }
`;

export const MobidictFilled = styled.div.attrs({
})`     
    z-index: 3;
    position: absolute;
    right: 12%;
`;

export const BannerTitle = styled.div.attrs({
})`           
    font-family: Poppins;
    font-size: 62px;
    font-weight: 700;
    line-height: 68.2px;
    text-align: left;
    color: ${props => props.theme.palette.white.main};
    position: absolute;
    max-width: 469px;
    right: 3%;
    bottom: 22%;
    @media (max-width: 769px) {
        font-size: 42px;
        line-height: 46.2px;
        right: -8%;
    }
    @media (max-width: 610px) {
        font-size: 32px;
        line-height: 35.2px;
        max-width: 300px;
        right: 5%;
    }
    @media (max-width: 450px) {
        font-size: 28px;
        line-height: 24.2px;
        max-width: 200px;
        right: 8%;
    }   
    
    @media (max-width: 400px) {
        font-size: 24px;
        line-height: 24.2px;
        max-width: 200px;
       right: 0;
    }
`;

export const Icon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))` 
    margin: 0 2px;       
    @media (max-width: 991px) {
        width: calc(100%);
        height: auto;
    } 
`;