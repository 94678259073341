import styled from 'styled-components'
import { Col } from 'reactstrap';


export const SideBackgroundImageContainer = styled.div.attrs({
})`          
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 767px){ 
        position: relative;
        min-height:initial; 
        width: 100%;

    }
`;

export const ImageBackground = styled.div.attrs({
})`           
    background: url('/images/background2.png') no-repeat center center;
    background-size: cover;
    width: 510px;
    height: 350px;
    display: flex;
    position: relative;
    @media(max-width: 999px){
        background-size: contain;
    }
`;
export const StyledCol = styled(Col).attrs({
})`         
    background: ${props => props.theme.palette.primary.main};
    @media(max-width: 920px){
        display: none;
    }
`;
export const StyledColRight = styled(Col).attrs({
})`          
    @media(max-width: 920px){
        width: 100%;
    }
`;
export const FormContent = styled.div.attrs({
})`           
    min-height:100vh;
    display: flex;
    background: ${props => props.theme.palette.colors.backgroundgrey};
    flex-direction: column;
    ${props => props.login && `
    justify-content: center;
    align-items: center;

`};

`;
export const AppLogo = styled.img.attrs({
    src: '/icons/logo-white.svg',
})`        
    width: 360px;
    position: absolute;
    z-index: 1;
    top: 65%;
    right: 8%;
    transform: translateY(-50%);
    @media(max-width: 999px){
        right: 0;
    }
`;

export const Content = styled.div.attrs({
})`           
    overflow:hidden;
`;

export const Touch = styled.div.attrs({
})`           
    cursor: pointer;
`;

export const ColContainer = styled.div.attrs({
})`           
    display: flex;
    @media(max-width: 768px){
        flex-direction: column;
    }
    ${props => props.reverse && `
        flex-flow: row-reverse;
    `}
`;
