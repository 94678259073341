import styled from 'styled-components'

export const HowWorksContainer = styled.div.attrs({
})`          
    background:  ${props => props.light ? props.theme.palette.colors.backgroundBanner3 : props.theme.palette.colors.background};
    width: 100%;
    min-height: ${props => props.small ? `640px` : `800px`};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 64px;
    flex-wrap: wrap;
   ${props => props.reverse ? `
     flex-flow: row-reverse nowrap;
     flex-wrap: wrap-reverse;
     ` : ``};

     padding: 24px;  
`;

export const HowWorksBanner = styled.div.attrs({
})`
    background: url(${props => props.banner}) no-repeat;
    background-size: contain;
    background-position: center;
    width: ${props => props.small ? `568px;` : `720px`};
    height: ${props => props.big ? `560px` : `480px`} ;
    gap: 16px;
    border: 0px 0px 12px 0px;
    opacity: 0px;
    @media (max-width: 991px) {
        width: 100%;
        max-height: 320px;
    }




`;

export const HowWorksContent = styled.div.attrs({
})`
    width: 416px;
    min-height: 361px;
    gap: 16px;
    opacity: 0px;
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
        width: 100%;
    }

    ${props => props.big ? `
        width: 568px;
        ` : ``};
`;
export const Logo = styled.img.attrs({
    src: '/icons/logo-mobdict.svg'
})`
    width: 49.41px;
    height: 34px;
`;

export const Title = styled.div.attrs({
})`
    font-size: 20px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const Text = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
`;

export const DownloadButton = styled.div.attrs({
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
        transform: scale(1.01);
    }
`;

export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    gap: 16px;
    margin-top: 16px;
    flex-wrap: wrap;
`;