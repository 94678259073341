import React, { useContext } from 'react'
import { InfoHeaderContainer, InfoHeaderText } from './styled'
import Button from 'components/Form/Button'
import { Icon } from 'ui/styled';
import { CoreContext } from 'context/CoreContext';
import useI18n from 'hooks/useI18n';

export default function InfoHeader() {

  const { setModal, plan, words } = useContext(CoreContext);

  const testDrive = true;
  const contract = false;

  const { t } = useI18n(false)
  
  // Período de teste: 7 dias restantes ou

  return (
    <>
      <InfoHeaderContainer testDrive={testDrive} onClick={() => setModal({ type: 'plans' })}>
        <InfoHeaderText>
          { plan?.id ? `${plan?.qtd_ditados} ${ t("dashboard_home_dicts_done") } / ${plan?.qtd_contratado} ${ t("dashboard_home_dicts_plan") }` : 
            testDrive ? `${ t("dashboard_home_dicts_test") }: ${words?.qtd_ditados} / ${words?.qtd_contratado} ${t("dashboard_home_dicts_dict")}` : `${ t("dashboard_home_dicts_test_done") }` }
        </InfoHeaderText>
        <Button
          link
          small
          nospace
          color='black'
          endIcon='chevron-right'
        >
          { t("dashboard_home_see_plans") }
        </Button>
      </InfoHeaderContainer>
    </>
  )
}

