import styled from "styled-components";

export const Overlay = styled.div.attrs({
})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.overlay};
    z-index: 1001;
    cursor: auto;
`;

export const ModalContainer = styled.div.attrs({
})`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;


export const ModalContent = styled.div.attrs({
})`

    background: ${props => props.theme.palette.colors.white};
    border-radius: 8px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 690px;
    width: 100%;
    overflow-y: auto; 
    max-height: 90vh;

    box-sizing: border-box;
    animation: slideDown 0.3s ease-out;

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    @media (max-width: 1000px) {
        padding: 20px;
        max-width: 100%;
        min-width: 100%;
    }

    @media (max-width: 480px) {
        padding: 15px;
        gap: 15px;
    }
`;

export const ModalLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const ModalTitleContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    padding: 16px 40px;
    position: relative;
`;

export const ModalBody = styled.div.attrs({
})`
    display: flex;
    gap: 20px;
    padding: 0px 40px 20px 40px;
    align-items: flex-end;
    flex-wrap: wrap;
    @media (max-width: 798px) {
        justify-content: center;
    }
`;

export const ButtonClose = styled.div.attrs({
})`
    position: absolute;
    right: 40px;
    @media (max-width: 798px) {
        right: 5px;
        top: 20px;
    }
`;