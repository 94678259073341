import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    ForgotContent,
    ForgotContainer
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoForgotPassword } from "services/authentication";
import { exposeMobdictError, exposeStrapiError } from "utils";
import { ButtonContainer, Icon, Title } from "ui/styled";
import { AskForgot } from "services/mobdict";
import { FRONTEND_ENDPOINT } from "services/api";
import useI18n from "hooks/useI18n";

export default function Forgot() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)

    const { t } = useI18n()

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)

    const valid = (verbose = false) => {

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error( t("dashboard_register_validation_email") ); }
            return false;
        }

        return true
    }

    const action = async () => { 

        if (!valid(true) || loading) { return; }
        setLoading(true)

        const result = await AskForgot({ 
            email: formValue('email')?.replace(/ /g, ''), 
            url: `${window.location.origin}/create-password`
        })

        console.log("Forgot result", result)

        setLoading(false)
        if( !exposeMobdictError(result, (typeof result?.data !== 'string') )){
            toast.success(result?.message)
            setStep(step + 1);
        }
    }

    const steps = useMemo(() => [
        {
            title: t("dashboard_forgot_step1_title"),
            text: t("dashboard_forgot_step1_text"),
            label: t("dashboard_register_step2_back"),
            labelNext: t("dashboard_register_step1_action"),
        },
        {
            title: t("dashboard_forgot_step2_title"),
            text: t("dashboard_forgot_step2_text"),
            label: t("dashboard_register_step2_action"),
        },

    ], []);

    const next = () => {
        if (step === steps.length - 2) {
            //save data
            action()
            return;
        }
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }

    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }

    return (
        <>
            <ContainerUnauthenticated forgot>
                <ForgotContainer>

                    <ForgotContent>
                        {!(step === 0) ? null :
                            <>
                                <Title hasIcon>
                                    <Icon icon='logo-mobdict' nomargin width={44} />
                                    {steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <Input noRadius placeholder="Email" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                                <FormSpacer />
                                <ButtonContainer>
                                    <Button nospace outline secondary colorBlack width={'125px'} onClick={() => back()}>
                                        <Icon icon="chevron-left" />
                                        {steps?.[step]?.label}
                                    </Button>
                                    <Button nospace secondary between width={'50%'}
                                        endIcon={'chevron-right'}
                                        loading={loading} onClick={action} >
                                        {steps?.[step]?.labelNext}
                                    </Button>
                                </ButtonContainer>
                            </>
                        }
                        {
                            !(step === 1) ? null : <>
                                <Title hasIcon>
                                    <Icon icon='logo-mobdict' nomargin width={44} />
                                    {steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <FormSpacer />
                                <ButtonContainer center>
                                    <Button width='50%' secondary nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                                </ButtonContainer>
                            </>
                        }
                    </ForgotContent>
                </ForgotContainer>
            </ContainerUnauthenticated>
        </>
    );
}