import React from "react";

import { Row, Col, Container } from 'reactstrap';

import CardSelect from 'components/Landpage/Card/Select'

import {
    BannerImage,
    BannerOut,
    BannerTitle,
    Icon,
    MobidictFilled,
    MobidictOutlined
} from "./styled";
import useI18n from "hooks/useI18n";


export default function Banner() {
    const {t} = useI18n(false)
    return (
        <>
            <BannerImage>
                <MobidictOutlined>
                    <Icon icon={'mobidictOutlined'} />
                </MobidictOutlined>
                <MobidictFilled>
                    <Icon icon={'mobidictFilled'} />
                    <BannerTitle>
                        { t("landpage_banner_balloon") }
                    </BannerTitle>
                </MobidictFilled>
            </BannerImage>
        </>
    );
}