import React, { useContext, useMemo } from 'react'
import { SayingButtonContainer, SayingErrorContainer, SayingErrorContent, SayingErrorImg, SayingErrorTableContainer, SayingErrorText } from './styled'
import { Title } from 'ui/styled'
import RightWrongTable from 'components/RightWrongTable'
import Button from 'components/Form/Button'
import HighlightedText from 'components/HighlightedText'
import { theme } from 'ui/theme-color'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'

export default function SayingError({ toCorrect, action, error }) {
  const { reinforcement, handleStateReset } = useContext(CoreContext)

  const { t } = useI18n(false)

  const wrong = useMemo(() => {
    return JSON.parse(toCorrect?.erradas || `[]`)
  }, [toCorrect])

  const correct = useMemo(() => {
    return JSON.parse(toCorrect?.correcoes || `{}`)
  }, [toCorrect])

  const omitted = useMemo(() => {
    return JSON.parse(toCorrect?.palavras_omitidas || `[]`)
  }, [toCorrect])

  return (
    <>
      <SayingErrorContainer>
        <SayingErrorImg />
        <SayingErrorContent>
          <Title small>
            { wrong?.length } { t("dashboard_dict_wrong_words") } / { omitted?.length } { t("dashboard_dict_wrong_omit") }
          </Title>
          <SayingErrorText>
            <HighlightedText error={ wrong?.length ? { ...error, text: toCorrect?.texto_correto || "", words: wrong, backgroundColor: theme.palette.wrong.main } : null }  />
          </SayingErrorText>

          <SayingErrorTableContainer reinforcement={reinforcement}>
            { ! wrong?.length ? null : <RightWrongTable item={{ correct, wrong }} /> }
            { ! omitted?.length ? null : <RightWrongTable items={omitted} /> }
          </SayingErrorTableContainer>
          <SayingButtonContainer>
            <Button width='240px' secondary outline nospace colorBlack onClick={handleStateReset}>
              { t("dashboard_dict_wrong_finish") }
            </Button>
            {
              !action ? null : 
              <Button width='240px' secondary nospace onClick={action}>
                { reinforcement ? t("dashboard_dict_wrong_challange") : t("dashboard_dict_wrong_reinforcement") }
              </Button>
            }
          </SayingButtonContainer>

        </SayingErrorContent>
      </SayingErrorContainer>
    </>
  )
}
